import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';

import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from "gatsby-plugin-image"

import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';

import Aboutback from "../components/aboutbg" // Tell Webpack this JS file uses this image

import styled from "styled-components";
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

  const Bline = styled.hr`
  width: 70px;
 border-bottom:2px solid #1c6a9c;
 margin-bottom:15px;
 margin-top:15px;
  `



    export default function IndexPage()
    {
   
      const { placeholderImage } = useStaticQuery(
       graphql`
         query {
           placeholderImage: file(relativePath: { eq: "homebg.jpg" }) {
             childImageSharp {
               gatsbyImageData(
                 width: 1050
                 placeholder: BLURRED
                 formats: [AUTO, WEBP, AVIF]
               )
             }
           }
   
         }
       `
     )
   
     const image = getImage(placeholderImage);
     
     // Use like this:
     const bgImage = convertToBgImage(image);

     return(

  <Layout>
    <Seo title="MRI - Patient Monitors - Ventilators" description="AFRICORP HEALTHCARE SYSTEMS LTD are the authorized distributors for Philips Medical Systems Nederland B.V. in Zambia" />
    <ThemeProvider theme={theme}>
 
      <Aboutback title="About Us" />

      <BackgroundImage
      Tag="section"
      // Spread bgImage into BackgroundImage:
      {...bgImage}
      preserveStackingContext
      style={{ backgroundAttachment:'fixed' }}
    >
        <div style={{ backgroundColor:'rgba(236, 236, 236, 0.68)' }}>
   <Container>
     <Box pt={4} pb={5}>
     <Grid container>
       <Grid item xs={12}>
       <Typography  paragraph variant="body1">
       AFRICORP HEALTHCARE SYSTEMS LTD are the authorized distributors for Philips Medical Systems Nederland B.V. in Zambia bringing in world class technologies consulting and solutions, innovative solutions for clients across a wide range of medical equipment such as CT scanners, MRI scanners, Ultrasound, Color Dopplers, C-arms, Mobile & Fixed Xray units, Mammography and CathLab.
         </Typography>
         <Typography  paragraph variant="body1">
         AfriCorp Healthcare Systems Ltd are the technology partners and offers a latest innovative healthcare technology as Consulter, System integrator and Solution provider. This puts our clients into the power of making right decisions independently from technology vendors
</Typography>

<Typography  paragraph variant="body1">
AfriCorp Healthcare Systems Ltd will help you to meet the economical challenges and we will help you to find new ways to improve your performance and set quality of care standards that are meeting international compatibility requirements.
</Typography>
<Typography  paragraph variant="body1">
AfriCorp Healthcare Systems Ltd has a long and distinguished track record of providing a wide range of healthcare technology consulting and implementation services to governments and agencies, public and private healthcare providers as well as to related sectors.
</Typography>
<Typography paragraph variant="body1">
Anything we do, must be in the best interest of our clients and their patients.
</Typography>
<Typography  paragraph variant="body1">
Optimize the healthcare facilities – with LOWER TOTAL COST OF OWNERSHIP
</Typography>

       </Grid>
     </Grid>
     </Box>
   </Container>
   </div>
   </BackgroundImage>

<Box bgcolor="grey.300">
<Container>
 
 <Box pt={4} pb={5}>
 <Grid container>
 <Grid item xs={12}>
 <Typography  gutterBottom variant="h3" component="h2" align="center">
  Why Healthcare Facilities Choose Us
   </Typography>
   <Bline/>
   </Grid>
   </Grid>
   
 <Box mt={2} p={1}>
   <Grid container>
   <Grid item xs={12} sm={4} md={4}>
     <Box p={1} >
   <Paper elevation={3} className="bio">
     <Box p={2}>
     <Typography  gutterBottom variant="h5" align="center">
   <b>Bio Medical Engineers</b>
   </Typography>
   <Bline/>
   <List>
     <ListItem><Typography  gutterBottom variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/> Quickdiagnostic</Typography></ListItem>
     <ListItem><Typography  gutterBottom variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/> Technical Support</Typography></ListItem>
     <ListItem><Typography  gutterBottom variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/> Confidently service equipment</Typography></ListItem>
     <ListItem><Typography  gutterBottom variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/> Reduce future failures</Typography></ListItem>
     <ListItem><Typography  gutterBottom variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/> Increase service</Typography></ListItem>
     <ListItem><Typography  gutterBottom variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/> productivity</Typography></ListItem>
   </List>
     </Box>
 </Paper>
 </Box>
     </Grid>

     <Grid item xs={12} sm={4} md={4}>
     <Box p={1}>
   <Paper elevation={3} className="bio">
     <Box p={2} css={{ height: 427 }}>
     <Typography  gutterBottom variant="h5" align="center">
   <b>Clinical Users</b>
   </Typography>
   <Bline/>
   <List>
     <ListItem><Typography  gutterBottom variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/> Higher equipment up time Reduce  </Typography></ListItem>
     <ListItem><Typography  gutterBottom variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/>  Technical Support  </Typography></ListItem>
     <ListItem><Typography  gutterBottom variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/>  interruptions  </Typography></ListItem>
     <ListItem><Typography  gutterBottom variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/>  High image quality  </Typography></ListItem>
     <ListItem><Typography  gutterBottom variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/> Increase scanning productivity  </Typography></ListItem>
   </List>
     </Box>
 </Paper>
 </Box>
     </Grid>

     <Grid item xs={12} sm={4} md={4}>
     <Box p={1}>
   <Paper elevation={3} className="bio">
     <Box p={2}  css={{ height: 427 }}>
     <Typography  gutterBottom variant="h5" align="center">
   <b>Administrators</b>
   </Typography>
   <Bline/>
   <List>
     <ListItem><Typography  gutterBottom variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7 }}/> Increase equipment availability</Typography></ListItem>
     <ListItem><Typography  gutterBottom variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7  }}/> Higher patient capacity</Typography></ListItem>
     <ListItem><Typography  gutterBottom variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7  }}/> Lowering service costs</Typography></ListItem>
     <ListItem><Typography  gutterBottom variant="body1"><DoubleArrowIcon style={{ color:'#fd0100', marginRight:7, position:'relative', top:7  }}/> Safe and reliable</Typography></ListItem>
   </List>
     </Box>
 </Paper>
 </Box>
 <Hidden mdUp>
    <Box mb={4}></Box>
    </Hidden>
     </Grid>


 </Grid>
 </Box>

 </Box>
</Container>
    </Box>
   

    </ThemeProvider>
  </Layout>
  
);
  }
